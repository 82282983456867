// reducers/coinReducer.js
// const initialState = {
//     coin: 0,
//     timer: null,
//   };
  
//   const coinReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'increment':
//         return { ...state, coin: state.coin + action.payload };
//       case 'decrement':
//         return { ...state, coin: state.coin - action.payload };
//       case 'START_TIMER':
//         return { ...state, timer: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default coinReducer;


// reducers/index.js

import { ActionTypes } from "../actioncreators/index";

const initialState = {
  coin: 0,
  timer: null,
  multitapTimer: null,
  energyLimitTimer: null,
  rechargingSpeedTimer: null,
  bot1Timer: null,
  bot2Timer: null,
  bot3Timer: null,

};

const coinReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INCREMENT_COIN:
      return {
        ...state,
        coin: state.coin + action.payload,
      };
    case ActionTypes.DECREMENT_COIN:
      return {
        ...state,
        coin: state.coin - action.payload,
      };
    case ActionTypes.START_TIMER:
      const { type, endTime } = action.payload;
      switch (type) {
        case 'multitap':
          return {
            ...state,
            multitapTimer: endTime,
          };
        case 'energyLimit':
          return {
            ...state,
            energyLimitTimer: endTime,
          };
        case 'rechargingSpeed':
          return {
            ...state,
            rechargingSpeedTimer: endTime,
          };
        case '1 week':
          return {
            ...state,
            rechargingSpeedTimer: endTime,
          };
        case '2 weeks':
          return {
            ...state,
            rechargingSpeedTimer: endTime,
          };
        case '1 month':
          return {
            ...state,
            rechargingSpeedTimer: endTime,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default coinReducer;

  