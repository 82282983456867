import React, { useState } from 'react';
import referral from '../assests/referral.png';
import task from '../assests/task.png';
import icon from '../assests/coin.png';
import fire from '../assests/fire.png';
import admin from '../assests/admin.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import WalletConnectButton from './WalletConnectButton';

const Boost = () => {
  const coin = useSelector(state => state.coinState.coin);
  const userState = useSelector((state) => state.userState);

  const boosters = [
    { name: 'Tapping Bot for 1 week', cost: 1400 },
    { name: 'Tapping Bot for 2 weeks', cost: 2400 },
    { name: 'Tapping Bot for 1 month', cost: 4400 },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [price,setPrice] = useState(0) 

  const openModal = (p) => {
    setPrice(p)
    setModalIsOpen(true);
  }
  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-[#111827] to-[#45526e] w-full text-white flex flex-col items-center p-4">
        <div className="text-center p-4 w-full">
          <h2 className="text-xl">Your Share balance</h2>
          <div className="text-yellow-500 text-3xl mt-3 flex justify-center">
            <img src={icon} alt="" className="w-6 h-6 mt-2" /> {userState.totalCoins}
          </div>
        </div>
        <div className="mt-6 w-full p-4">
          {boosters.map((booster, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-4 bg-gray-800 rounded-lg mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
              style={{
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
                background: 'linear-gradient(145deg, #2b2b2b, #333333)'
              }}
              onClick={()=>{openModal(booster.cost)}}
            >
              <div>{booster.name}</div>
              <div className="flex">
                <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" /> {booster.cost}
              </div>
            </div>
          ))}
        </div>
        <footer className="w-full flex justify-around items-center py-4 mt-auto bg-gray-800 rounded-t-lg shadow-lg"
        style={{
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
          background: 'linear-gradient(145deg, #2b2b2b, #333333)'
        }}
      >
        <Link to="/ref">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={referral} alt="Ref" className="w-6 h-6 mb-1" />
            <span>Ref</span>
          </button>
        </Link>
        <Link to="/tab">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={task} alt="Task" className="w-6 h-6 mb-1" />
            <span>Task</span>
          </button>
        </Link>
        <Link to={`/${userState.id}/${userState.name}`}>
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={icon} alt="Tap" className="w-6 h-6 mb-1" />
            <span>Tap</span>
          </button>
        </Link>
        <Link to="/boost">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={fire} alt="Boost" className="w-6 h-6 mb-1" />
            <span>Boost</span>
          </button>
        </Link>
      </footer>
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded-lg shadow-lg w-80 max-h-44 flex flex-col items-center">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <WalletConnectButton price={price*0.005} />
          </div>
        </div>
      )}
    </>
  );
};

export default Boost;
