import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store';
import { Provider } from 'react-redux';
import { PersistGate} from "redux-persist/integration/react"
import {persistStore} from "redux-persist"



let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'));






root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate persistor={persistor}>

    <App />

    </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
