// src/state/reducers/userReducer.js
import { ActionTypes } from '../actioncreators';

const initialState = {
  user: null,
  totalCoins: 0,
  name: 'Guest',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
        id: action.payload.tele_id,
        totalCoins: action.payload.total_coins,
        name: action.payload.first_name || 'Guest',
      };
    case ActionTypes.CLEAR_USER_DATA:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
