import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/actioncreators/index";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import referral from '../assests/referral.png';
import task from '../assests/task.png';
import icon from '../assests/coin.png';
import fire from '../assests/fire.png';

const CoinPage = () => {
  const params = useParams();

  const id = params.id || '0000000';
  const code = params.code || null;
  const first_name = params.first_name || 'Guest';

  const [apiCalled, setApiCalled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [remainingClicks, setRemainingClicks] = useState(() => {
    const storedClicks = localStorage.getItem('remainingClicks');
    return storedClicks !== null ? parseInt(storedClicks, 10) : 500;
  });
  const [incrementAnimations, setIncrementAnimations] = useState([]);
  const [timeLeft, setTimeLeft] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { incrementCoin, startTimer, setUserData, clearUserData } = bindActionCreators(actionCreators, dispatch);

  const coin = useSelector((state) => state.coinState.coin);
  const timer = useSelector((state) => state.coinState.timer);
  const multitapTimer = useSelector((state) => state.coinState.multitapTimer);
  const energyLimitTimer = useSelector((state) => state.coinState.energyLimitTimer);
  const rechargingSpeedTimer = useSelector((state) => state.coinState.rechargingSpeedTimer);
  const userState = useSelector((state) => state.userState);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const updateCoinsAPI = (coinCount) => {
    const tele_id = id;
    axios.put(`https://api.oxswap.app/addCoin`, { tele_id, coinCount })
      .then(response => console.log('Coins updated successfully'))
      .catch(error => console.error('Error updating coins:', error));
  };

  const handleCoinClick = () => {
    if (remainingClicks > 0) {
      if ((userState.totalCoins + 1) % 3 === 0) {
        updateCoinsAPI(userState.totalCoins);
      }
      const incrementValue = multitapTimer ? 2 : 1;
      const decrementValue = energyLimitTimer ? 0 : 1;
      incrementCoin(incrementValue);
      dispatch(setUserData({ ...userState, tele_id: userState.id, first_name: userState.name, total_coins: userState.totalCoins + incrementValue }));
      setRemainingClicks((prevClicks) => prevClicks - decrementValue);
      setIsClicked(true);

      const newAnimation = { id: Date.now(), value: incrementValue };
      setIncrementAnimations((prevAnimations) => [...prevAnimations, newAnimation]);
      setTimeout(() => {
        setIncrementAnimations((prevAnimations) => prevAnimations.filter((anim) => anim.id !== newAnimation.id));
      }, 1000);
    }
  };

  const insertData = async () => {
    if (apiCalled) {
      return;
    }

    const capitalizedFirstName = capitalizeFirstLetter(first_name);

    const updatedUserInfo = code
      ? { tele_id: id, ref: code, first_name: capitalizedFirstName }
      : { tele_id: id, first_name: capitalizedFirstName };

    try {
      const endpoint = code ? 'affiliate' : 'create';
      const user = await axios.post(`https://api.oxswap.app/${endpoint}`, updatedUserInfo);
      setApiCalled(true); // Mark API call as completed
      dispatch(setUserData(user.data));
      setRemainingClicks(500);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const tele_id = id;
      const response = await axios.get(`https://api.oxswap.app/get-user/${tele_id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        params: {
          timestamp: new Date().getTime(), // Add a cache-busting query parameter
        }
      });
      if (!response.data.first_name) {
        response.data.first_name = first_name; // Set first_name from useParams() if it's falsy or different
      }
      dispatch(setUserData(response.data));
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('remainingClicks', remainingClicks);
  }, [remainingClicks]);


  useEffect(() => {
    const storedDate = localStorage.getItem('lastResetDate');
    const currentDate = new Date().toLocaleDateString('en-GB');
    if (storedDate !== currentDate) {
      localStorage.setItem('lastResetDate', currentDate);
      setRemainingClicks(500);
      localStorage.setItem('remainingClicks', 500);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      console.log("fetch user")
      await fetchUser();
      if (!apiCalled) {
        await insertData();
      }
      await fetchUser();
    };
    initializeData();
  }, [id, first_name]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      if (timer && now > timer) startTimer(null);
      if (multitapTimer && now > multitapTimer) startTimer('multitap', null);
      if (energyLimitTimer && now > energyLimitTimer) startTimer('energyLimit', null);
      if (rechargingSpeedTimer && now > rechargingSpeedTimer) startTimer('rechargingSpeed', null);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, multitapTimer, energyLimitTimer, rechargingSpeedTimer, startTimer]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const nextMidnight = new Date();
      nextMidnight.setHours(24, 0, 0, 0);
      const timeUntilMidnight = nextMidnight - now;

      const hours = String(Math.floor((timeUntilMidnight / (1000 * 60 * 60)) % 24)).padStart(2, '0');
      const minutes = String(Math.floor((timeUntilMidnight / (1000 * 60)) % 60)).padStart(2, '0');
      const seconds = String(Math.floor((timeUntilMidnight / 1000) % 60)).padStart(2, '0');

      setTimeLeft(`${hours}:${minutes}:${seconds}`);
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (rechargingSpeedTimer) {
      const interval = setInterval(() => {
        setRemainingClicks((prevClicks) => Math.min(prevClicks + 1, 500));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [rechargingSpeedTimer]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#111827] to-[#45526e] text-white flex items-center justify-center">
        <div className="text-center">
          <div className="spinner"></div> {/* Add your loading spinner here */}
          <style>{`
            .spinner {
              border: 8px solid rgba(255, 255, 255, 0.2);
              border-top: 8px solid #fff;
              border-radius: 50%;
              width: 60px;
              height: 60px;
              animation: spin 1s linear infinite;
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#111827] to-[#45526e] text-white flex flex-col items-center p-4 relative">
      <div className="absolute top-4 left-4 bg-black bg-opacity-50 text-yellow-400 p-2 rounded-lg flex items-center">
        <span role="img" aria-label="clock" className="mr-2">⏲️</span> {timeLeft}
      </div>
      <main className="flex flex-col items-center w-full mt-5">
        <div className="text-center my-8">
          <div className="flex items-center justify-center">
            <img src={icon} alt="Coin Icon" className="w-6 h-6 mr-2 mt-1" />
            <h2 className="text-3xl font-bold">{userState.totalCoins}</h2>
          </div>
          <p className="text-gray-400 mt-2 flex items-center justify-center">
            <span className="mr-1">Welcome {userState.name}!</span>
          </p>
        </div>
        <div className="w-full flex justify-center mb-8">
          <div className="relative">
            <img
              src={icon}
              alt="Coin"
              className={`w-40 h-40 cursor-pointer ${isClicked ? 'coin-click-animation' : ''} transform transition-transform duration-300 hover:scale-110`}
              onClick={handleCoinClick}
              onMouseDown={() => setIsClicked(true)}
              onMouseUp={() => setIsClicked(false)}
              onMouseLeave={() => setIsClicked(false)}
            />
            {incrementAnimations.map((anim) => (
              <div key={anim.id} className="coin-increment" style={{ top: '50%', left: '50%' }}>
                +{anim.value}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full text-center mb-8">
          <div className="flex items-center justify-center">
            <span className="text-yellow-400 text-xl font-bold mr-2">⚡</span>
            <p className="text-lg">{remainingClicks}/500</p>
          </div>
          <div className="w-full bg-gray-700 rounded-full h-3 mt-2 relative" style={{ boxShadow: 'inset 2px 2px 5px rgba(0, 0, 0, 0.6), inset -2px -2px 5px rgba(255, 255, 255, 0.2)' }}>
            <div className="bg-yellow-400 h-3 rounded-full" style={{
              width: `${(remainingClicks / 500) * 100}%`,
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.6), -2px -2px 5px rgba(255, 255, 255, 0.2)'
            }}></div>
          </div>
        </div>
      </main>
      <footer className="w-full flex justify-around items-center py-4 mt-auto bg-gray-800 rounded-t-lg shadow-lg"
        style={{
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
          background: 'linear-gradient(145deg, #2b2b2b, #333333)'
        }}
      >
        <Link to="/ref">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={referral} alt="Ref" className="w-6 h-6 mb-1" />
            <span>Ref</span>
          </button>
        </Link>
        <Link to="/tab">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={task} alt="Task" className="w-6 h-6 mb-1" />
            <span>Task</span>
          </button>
        </Link>
        <Link to={`/${userState.id}/${userState.name}`}>
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={icon} alt="Tap" className="w-6 h-6 mb-1" />
            <span>Tap</span>
          </button>
        </Link>
        <Link to="/boost">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={fire} alt="Boost" className="w-6 h-6 mb-1" />
            <span>Boost</span>
          </button>
        </Link>
      </footer>
    </div>
  );
};

export default CoinPage;
