import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const Admin = () => {
  const { setIsAuthenticated } = useContext(AuthContext);

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  const [user, setUser] = useState([]);
  const [stats, setStats] = useState({});
  const [months, setMonths] = useState([]);

  const fetchAllUsers = async () => {
    const res = await axios.get(`https://api.oxswap.app/allUsers`);
    setUser(res.data.users);
  };

  const fetchStatistics = async () => {
    const res = await axios.get(`https://api.oxswap.app/userStatistics`);
    setStats(res.data);
  };

  const getMonthNames = () => {
    const now = new Date();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const currentMonth = now.getMonth();
    const previousMonth = (currentMonth + 11) % 12;
    return [monthNames[previousMonth], monthNames[currentMonth]];
  };

  useEffect(() => {
    fetchAllUsers();
    fetchStatistics();
    setMonths(getMonthNames());
  }, []);

  return (
    <div className='w-full min-h-screen p-5 bg-gradient-to-b from-[#111827] to-[#45526e] text-white'>
      <div className="flex justify-between items-center mb-5">
        <h1 className='text-3xl font-bold'>Admin Dashboard</h1>
        <button
          onClick={handleLogout}
          className="flex items-center text-white bg-red-700 px-3 py-2 rounded-md hover:bg-red-600 focus:outline-none"
        >
          Logout
        </button>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-10'>
        <div className='p-5 bg-gray-800 rounded-md shadow-md'>
          <div className="flex items-center mb-2">
            <span className='text-2xl mr-2'>👥</span>
            <h2 className='text-xl font-semibold'>User Statistics</h2>
          </div>
          <p>Total Users: {stats.totalUsers}</p>
          <p>Users joined Telegram: {stats.telegramUsers}</p>
          <p>Users joined Twitter: {stats.twitterUsers}</p>
        </div>
        <div className='p-5 bg-gray-800 rounded-md shadow-md'>
          <div className="flex items-center mb-2">
            <span className='text-2xl mr-2'>🛒</span>
            <h2 className='text-xl font-semibold'>Purchases</h2>
          </div>
          <p>Bot 1 Purchases {months[0]}: {stats.previousMonthBot1Purchases}</p>
          <p>Bot 2 Purchases {months[0]}: {stats.previousMonthBot2Purchases}</p>
          <p>Bot 3 Purchases {months[0]}: {stats.previousMonthBot3Purchases}</p>
          <p>Bot 1 Purchases {months[1]}: {stats.currentMonthBot1Purchases}</p>
          <p>Bot 2 Purchases {months[1]}: {stats.currentMonthBot2Purchases}</p>
          <p>Bot 3 Purchases {months[1]}: {stats.currentMonthBot3Purchases}</p>
        </div>
      </div>
      <div className='overflow-hidden'>
        <h2 className='text-2xl font-bold mb-5'>All Users</h2>
        <table className='border-collapse border-y border-slate-500 w-full text-white'>
          <thead className='bg-gray-700'>
            <tr>
              <th className='py-3 px-4'>User's Name</th>
              <th className='py-3 px-4'>Check Complete Detail</th>
            </tr>
          </thead>
          <tbody className='bg-gray-800'>
            {user.map((u) => (
              <tr key={u.tele_id} className='hover:bg-gray-700'>
                <td className='py-3 px-4 border-b border-gray-700'>{u.first_name? u.first_name : 'Unknown'}</td>
                <td className='py-3 px-4 border-b border-gray-700 text-blue-500'>
                  <Link to={`/user/${u.tele_id}`} className='hover:underline'>Show Detail</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
