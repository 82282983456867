import React, { useState,useEffect } from 'react';
import axios from 'axios';
import TabContent from './TabContent';
import referral from '../assests/referral.png';
import task from '../assests/task.png';
import icon from '../assests/coin.png';
import fire from '../assests/fire.png';
import admin from '../assests/admin.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/actioncreators/index";

const TabPage = () => {
  const [activeTab, setActiveTab] = useState('special');
  const [coinCount, setCoinCount] = useState(0);
  const dispatch = useDispatch();
  const { incrementCoin, startTimer, setUserData, clearUserData } = bindActionCreators(actionCreators, dispatch);
  const userState = useSelector((state) => state.userState);


  const incrementCoins = () => {
    setCoinCount(coinCount + 1);
  };
  
  

  const tabStyle = {
    background: '#cc8d2f', // Main tab background color
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
    border: '1px solid #e0a84a', // Lighter border color
  };

  const tabActiveStyle = {
    background: '#e0a84a', // Slightly lighter background for active tab
    color: '#fff',
    padding: '8px 16px',
    borderRadius: '5px',
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#111827] to-[#45526e] w-full text-white flex flex-col items-center p-4">
      <div className="mt-4 px-4 w-full">
        <div className="flex justify-around p-2 rounded-lg w-full" style={tabStyle}>
          <button
            className={`tab ${activeTab === 'special' ? 'tab-active' : ''}`}
            style={activeTab === 'special' ? tabActiveStyle : {}}
            onClick={() => setActiveTab('special')}
          >
            Special
          </button>
          <button
            className={`tab ${activeTab === 'Boosters' ? 'tab-active' : ''}`}
            style={activeTab === 'Boosters' ? tabActiveStyle : {}}
            onClick={() => setActiveTab('Boosters')}
          >
            Boosters
          </button>
          <button
            className={`tab ${activeTab === 'refTasks' ? 'tab-active' : ''}`}
            style={activeTab === 'refTasks' ? tabActiveStyle : {}}
            onClick={() => setActiveTab('refTasks')}
          >
            Ref Tasks
          </button>
        </div>
      </div>
      <TabContent activeTab={activeTab} />
      <footer className="w-full flex justify-around items-center py-4 mt-auto bg-gray-800 rounded-t-lg shadow-lg"
        style={{
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
          background: 'linear-gradient(145deg, #2b2b2b, #333333)'
        }}
      >
        <Link to="/ref">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={referral} alt="Ref" className="w-6 h-6 mb-1" />
            <span>Ref</span>
          </button>
        </Link>
        <Link to="/tab">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={task} alt="Task" className="w-6 h-6 mb-1" />
            <span>Task</span>
          </button>
        </Link>
        <Link to={`/${userState.id}/${userState.name}`}>
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={icon} alt="Tap" className="w-6 h-6 mb-1" />
            <span>Tap</span>
          </button>
        </Link>
        <Link to="/boost">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={fire} alt="Boost" className="w-6 h-6 mb-1" />
            <span>Boost</span>
          </button>
        </Link>

      </footer>
    </div>
  )
}

export default TabPage;
