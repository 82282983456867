import React, { useState, useEffect } from 'react';
import { initWeb3, sendPayment } from '../utils/web3';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/actioncreators/index";
import axios from 'axios';

const WalletConnectButton = ({ price }) => {
  const dispatch = useDispatch();
  const { incrementCoin, startTimer, decrementCoin } = bindActionCreators(actionCreators, dispatch);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const connectWallet = async () => {

    try {
      const web3 = await initWeb3();
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      setError(null);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      if (error.message.includes("User closed modal")) {
        setError('Connection cancelled. Please try again.');
      } else {
        setError('Failed to connect wallet. Please try again using QR Code');
      }
    }
  };

  const updateBot = async (timerType) => {
    const tele_id = localStorage.getItem('id');
    const update = {
      timerType, tele_id
    };
    try {
      const user = await axios.put(`https://api.oxswap.app/bot`, update);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }

  const handlePayment = async () => {
    try {
      setLoading(true);
      const receipt = await sendPayment('0xed356C643eD7019023a3f0492b2258d1B709451B', price.toString());
      console.log('Payment receipt:', receipt);

      if (receipt.status) {
        let timerType;
        let duration;

        if (price === 7) {
          timerType = '1 week';
          duration = 7 * 24 * 60 * 60 * 1000;
        } else if (price === 12) {
          timerType = '2 weeks';
          duration = 14 * 24 * 60 * 60 * 1000;
        } else if (price === 22) {
          timerType = '1 month';
          duration = 30 * 24 * 60 * 60 * 1000;
        }

        const endTime = Date.now() + duration;
        startTimer(timerType, endTime);

        localStorage.setItem(`${timerType}EndTime`, endTime.toString());

        await updateBot(timerType);
        setError(null);

        const intervalId = setInterval(() => {
          const currentTime = Date.now();
          if (currentTime >= endTime) {
            clearInterval(intervalId);
          } else {
            incrementCoin(1);
          }
        }, 1000);
      } else {
        throw new Error('Payment unsuccessful');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error sending payment:', error);
      setError('Failed to send payment. Please try again.');
      setLoading(false);
    }
  };


  const disconnectWallet = () => {
    setAccount(null);
    setError('Wallet disconnected. Please reconnect to proceed.');
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <button
        onClick={connectWallet}
        className="px-4 py-4 bg-blue-500 text-white rounded hover:bg-blue-700 mb-4"
        disabled={loading}
      >
        {account ? `Connected: ${account}` : 'Connect Wallet'}
      </button>
      {error && <p className="text-red-500">{error}</p>}
      {account && (
        <div>
          <button
            onClick={handlePayment}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 mb-4"
            disabled={loading}
          >
            {loading ? 'Sending...' : `Pay ${price} USDT`}
          </button>
          <button
            onClick={disconnectWallet}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
            disabled={loading}
          >
            Disconnect Wallet
          </button>
        </div>
      )}
    </div>
  );
};

export default WalletConnectButton;
