import './App.css';
import Boost from './Components/Boost';
import CoinPage from './Components/CoinPage';
import Referral from './Components/Referral';
import TabPage from './Components/TabPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Admin from './Components/Admin';
import User from './Components/User';
import { AuthProvider } from './Context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import Login from './Components/Login';

// Define routes with authentication where needed
const url = "http:localhost:5000"
const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/user/:id",
    element: (
      <ProtectedRoute>
        <User />
      </ProtectedRoute>
    )
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <Admin />
      </ProtectedRoute>
    )
  },
  {
    path: "/",
    element: <CoinPage url={url} />
  },
  {
    path: "/:id/:first_name",
    element: <CoinPage />
  },
  {
    path: "/:code/:id/:first_name",
    element: <CoinPage />
  },
  {
    path: "/ref",
    element: <Referral />
  },
  {
    path: "/tab",
    element: <TabPage />
  },
  {
    path: "/boost",
    element: <Boost />
  }
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
