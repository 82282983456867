import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import reducers from "./reducers/index";
import storage from "redux-persist/lib/storage";
import {persistReducer} from 'redux-persist'


const persistConfig = {
    key : "root",
    version: 1,
    storage
    
}

const persistedReducer = persistReducer(persistConfig,reducers)

export const store = createStore(persistedReducer,{},applyMiddleware(thunk))

