// src/state/reducers/index.js
import { combineReducers } from 'redux';
import coinReducer from './coinreducers'; // Assuming you have a coinReducer
import userReducer from './userReducer';

const rootReducer = combineReducers({
  coinState: coinReducer,
  userState: userReducer,
});

export default rootReducer;
