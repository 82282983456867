// actionCreators.js
// export const incrementCoin = (amount) => {
//     return {
//       type: 'increment',
//       payload: amount,
//     };
//   };
  
//   export const decrementCoin = (amount) => {
//     return {
//       type: 'decrement',
//       payload: amount,
//     };
//   };
  
//   export const startTimer = (payload) => {
//     return {
//       type: 'START_TIMER',
//       payload,
//     };
//   };
  
// actions/index.js

// src/state/actioncreators/index.js
export const ActionTypes = {
  INCREMENT_COIN: "INCREMENT_COIN",
  DECREMENT_COIN: "DECREMENT_COIN",
  START_TIMER: "START_TIMER",
  SET_USER_DATA: "SET_USER_DATA",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
};

export const actionCreators = {
  incrementCoin: (value) => ({
    type: ActionTypes.INCREMENT_COIN,
    payload: value,
  }),
  decrementCoin: (value) => ({
    type: ActionTypes.DECREMENT_COIN,
    payload: value,
  }),
  startTimer: (type, endTime) => ({
    type: ActionTypes.START_TIMER,
    payload: { type, endTime },
  }),
  setUserData: (userData) => ({
    type: ActionTypes.SET_USER_DATA,
    payload: userData,
  }),
  clearUserData: () => ({
    type: ActionTypes.CLEAR_USER_DATA,
  }),
};
