import React, { useEffect, useState } from 'react';
import referral from '../assests/referral.png';
import task from '../assests/task.png';
import icon from '../assests/coin.png';
import fire from '../assests/fire.png';
import admin from '../assests/admin.png'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/actioncreators/index";

const Referral = () => {
  const dispatch = useDispatch();
  const { incrementCoin, startTimer, setUserData, clearUserData } = bindActionCreators(actionCreators, dispatch);
  const userState = useSelector((state) => state.userState);
  const [showTooltip, setShowTooltip] = useState(false);
  const [code , setCode] = useState('')
  const [count,setCount] = useState(0)
  const getAffiliateCode = async () => {
    try {
      const tele_id = userState.id;
      const res = await axios.get(`https://api.oxswap.app/aff/${tele_id}`);
      setCode(res.data.affiliate) 
      setCount(res.data.total_ref)
    } catch (error) {
      console.error('Failed to fetch affiliate code:', error);
    }
  };
  
  const handleCopy = () => {
    navigator.clipboard.writeText(`https://t.me/ox_coin_tapbot?start=${code}`)
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 500); // Tooltip will disappear after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  useEffect(()=>{
    getAffiliateCode();
  },[])

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#111827] to-[#45526e] text-white flex flex-col items-center p-4">
      <main className="flex flex-col items-center w-full">
        <div className="text-center my-8">
          <h2 className="text-3xl font-bold">{count} Referrals</h2>
          <p className="text-green-400 mt-2">+{count}</p>
        </div>
        <div className="w-full flex justify-center mb-8">
          <div className="bg-gray-800 w-11/12 p-4 rounded-lg flex flex-col items-center" style={{
            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
          }}>
            <p>My invite link:</p>
            <div className="bg-gray-700 p-2 w-full rounded-lg flex items-center mt-2">
              <input
                type="text"
                readOnly
                value={`https://t.me/ox_coin_tapbot?start=${code}`}
                className="bg-transparent text-white text-sm p-1 outline-none w-full break-words"
              />
            </div>
            <div className="relative w-full flex justify-center mt-3">
              <div className="w-1/2 flex flex-col items-center">
                <button
                  onClick={handleCopy}
                  className="bg-[#cc8d2f] hover:bg-yellow-600 w-full p-1 rounded-lg"
                >
                  Copy
                </button>
                {showTooltip && (
                  <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
                    Link copied!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="w-full flex justify-around items-center py-4 mt-auto bg-gray-800 rounded-t-lg shadow-lg"
        style={{
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
          background: 'linear-gradient(145deg, #2b2b2b, #333333)'
        }}
      >
        <Link to="/ref">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={referral} alt="Ref" className="w-6 h-6 mb-1" />
            <span>Ref</span>
          </button>
        </Link>
        <Link to="/tab">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={task} alt="Task" className="w-6 h-6 mb-1" />
            <span>Task</span>
          </button>
        </Link>
        <Link to={`/${userState.id}/${userState.name}`}>
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={icon} alt="Tap" className="w-6 h-6 mb-1" />
            <span>Tap</span>
          </button>
        </Link>
        <Link to="/boost">
          <button className="flex flex-col items-center transform hover:scale-110 transition-transform duration-300">
            <img src={fire} alt="Boost" className="w-6 h-6 mb-1" />
            <span>Boost</span>
          </button>
        </Link>

      </footer>
    </div>
  )
}

export default Referral;
