import React, { useState, useEffect } from 'react';
import icon from '../assests/coin.png';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/actioncreators/index';
import axios from 'axios';

const TabContent = ({ activeTab }) => {
  const [u,setU] = useState(null)
  const dispatch = useDispatch();
  const { incrementCoin, startTimer, setUserData, clearUserData,decrementCoin } = bindActionCreators(actionCreators, dispatch);
  const userState = useSelector((state) => state.userState);

  const coin = useSelector((state) => state.coinState.coin);

  const [buttonDisabled, setButtonDisabled] = useState({
    telegram: false,
    twitter: false,
    multitap: false,
    energyLimit: false,
    rechargingSpeed: false,
    invite1:true,
    invite3:true,
    invite10:true,
  });

  

  
  const [telegramActivated, setTelegramActivated] = useState(false);
  const [twitterActivated, setTwitterActivated] = useState(false);
  const [multitap, setMultitap] = useState(false);
  const [energy, setEnergy] = useState(false);

  useEffect(() => {
    const checkTimers = () => {
      const multitapEndTime = localStorage.getItem('multitapEndTime');
      const energyLimitEndTime = localStorage.getItem('energyLimitEndTime');
      const rechargingSpeedEndTime = localStorage.getItem('rechargingSpeedEndTime');

      setButtonDisabled((prevState) => ({
        ...prevState,
        multitap: multitapEndTime && new Date().getTime() < multitapEndTime,
        energyLimit: energyLimitEndTime && new Date().getTime() < energyLimitEndTime,
        rechargingSpeed: rechargingSpeedEndTime && new Date().getTime() < rechargingSpeedEndTime,
      }));
    };

    checkTimers();
    const interval = setInterval(checkTimers, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  const taskCardStyle = {
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1)',
    background: 'linear-gradient(145deg, #2b2b2b, #333333)',
  };

  const fetchUser = async (id) => {
    try {
      const response = await axios.get(`https://api.oxswap.app/detail/${id}`);
      console.log("User data fetched:", response.data);
      setU(response.data)
      if(response.data.total_ref >= 1 && response.data.ref1 === false){
        setButtonDisabled((prevState) => ({ ...prevState, invite1: false }));
      }
      if(response.data.total_ref >= 3 && response.data.ref3 === false){
        setButtonDisabled((prevState) => ({ ...prevState, invite3: false }));
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    const id = userState.id
    if (id) {
      fetchUser(id);
    } else {
    }
  }, []);
  
  const handleButtonClick = async (platform) => {
    const id = userState.id

    if (!id) {
      alert('User ID not found in localStorage');
      return;
    }

    const checkAndOpenLink = async (url, field, link, updateUrl) => {
      window.open(link, '_blank');
      try {
        const response = await axios.get(url);

        if (!response.data[field]) {
          incrementCoin(200);
          await new Promise((resolve) => setTimeout(resolve, 100)); // Wait to ensure state updates
          await axios.put(updateUrl);
        } else {
          if (platform === 'telegram') {
            setTelegramActivated(true);
            setTimeout(() => setTelegramActivated(false), 5000); // Hide message after 1 second
          } else if (platform === 'twitter') {
            setTwitterActivated(true);
            setTimeout(() => setTwitterActivated(false), 5000); // Hide message after 1 second
          }
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error checking or updating status');
      }
    };

    if (platform === 'telegram') {
      setButtonDisabled((prevState) => ({ ...prevState, telegram: true }));
      await checkAndOpenLink(
        `https://api.oxswap.app/telegram/${id}`,
        'telegram',
        'https://t.me/Oxcoinoffical', // Replace with your actual Telegram link
        `https://api.oxswap.app/telegram/${id}`
      );
    } else if (platform === 'twitter') {
      setButtonDisabled((prevState) => ({ ...prevState, twitter: true }));
      await checkAndOpenLink(
        `https://api.oxswap.app/twitter/${id}`,
        'twitter',
        'https://x.com/oxcoinofficial?s=09', // Replace with your actual Twitter link
        `https://api.oxswap.app/twitter/${id}`
      );
    } else if (platform === 'multitap' && coin >= 200) {
      setMultitap(true);
      decrementCoin(200);
      const endTime = new Date().getTime() + 20 * 60 * 1000; // 1 hour timer
      startTimer('multitap', endTime);
      localStorage.setItem('multitapEndTime', endTime);
      setButtonDisabled((prevState) => ({ ...prevState, multitap: true }));
      setTimeout(() => {
        setMultitap(false);
      }, 5000);
    } else if (platform === 'energyLimit' && coin >= 400) {
      setEnergy(true);
      decrementCoin(400);
      const endTime = new Date().getTime() + 20 * 60 * 1000; // 1 hour timer
      startTimer('energyLimit', endTime);
      localStorage.setItem('energyLimitEndTime', endTime);
      setButtonDisabled((prevState) => ({ ...prevState, energyLimit: true }));
      setTimeout(() => {
        setEnergy(false);
      }, 5000);
    } else if (platform === 'rechargingSpeed' && coin >= 2000) {
      decrementCoin(2000);
      const endTime = new Date().getTime() + 3600 * 1000; // 1 hour timer
      startTimer('rechargingSpeed', endTime);
      localStorage.setItem('rechargingSpeedEndTime', endTime);
      setButtonDisabled((prevState) => ({ ...prevState, rechargingSpeed: true }));
    } else {
      alert('Insufficient coins');
    }
  };


  const updateInvite = async(type)=>{
    const tele_id = userState.id
    const update = {
      type,tele_id
    }
    try {
      const user = await axios.put(`https://api.oxswap.app/invite`,update)
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  }

  const handleInvite = (type) =>{
      if(type === 'invite1'){
        incrementCoin(50);
        updateInvite(type)
        setButtonDisabled((prevState) => ({ ...prevState, invite1: true }));
      }
      if(type === 'invite3'){
        incrementCoin(600);
        updateInvite(type)
        setButtonDisabled((prevState) => ({ ...prevState, invite1: true }));
      }
  }

  if (activeTab === 'special') {
    return (
      <div className="p-4 w-full">
        {telegramActivated && (
          <p className="text-green-700 mb-[20px] mt-2">Telegram reward is already collected!</p>
        )}
        {twitterActivated && (
          <p className="text-green-700 mb-[20px] mt-2">Twitter reward is already collected!</p>
        )}
        <div
          className="task-card bg-gray-800 rounded-lg w-full p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <div className="flex justify-between items-center">
            <span>Join us on Telegram</span>
            <button
              className={`flex text-sm text-black px-2 py-1 rounded shadow-xl ${
                buttonDisabled.telegram ? 'cursor-not-allowed opacity-100' : ''
              }`}
              onClick={() => handleButtonClick('telegram')}
              // disabled={buttonDisabled.telegram}
            >
              <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
              200
            </button>
          </div>
        </div>
        <div
          className="task-card bg-gray-800 rounded-lg w-full p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <div className="flex justify-between items-center">
            <span>Join us on Twitter</span>
            <button
              className={`flex text-sm text-black px-2 py-1 rounded shadow-xl ${
                buttonDisabled.twitter ? 'cursor-not-allowed opacity-100' : ''
              }`}
              onClick={() => handleButtonClick('twitter')}
              // disabled={buttonDisabled.twitter}
            >
              <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
              200
            </button>
          </div>
        </div>
      </div>
    );
  } else if (activeTab === 'Boosters') {
    return (
      <div className="p-4 w-full">
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Multitap</span>
          <button
            className={`flex text-sm text-black px-2 py-1 rounded shadow-xl ${
              buttonDisabled.multitap ? 'cursor-not-allowed opacity-100' : ''
            }`}
            onClick={() => handleButtonClick('multitap')}
            disabled={buttonDisabled.multitap}
          >
            <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            200
          </button>
          {multitap && (
            <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
              Multitap is activated for 20 minutes
            </div>
        )}
        </div>
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Energy Limit</span>
          <button
            className={`flex text-sm text-black px-2 py-1 rounded shadow-xl ${
              buttonDisabled.energyLimit ? 'cursor-not-allowed opacity-100' : ''
            }`}
            onClick={() => handleButtonClick('energyLimit')}
            disabled={buttonDisabled.energyLimit}
          >
            <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            400
          </button>
          {energy && (
            <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2">
              EnergyLimit is Activated! Now you can tap unlimited for 20 minutes
            </div>
        )}
        </div>
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Recharging Speed</span>
          <button
            className={`flex text-sm text-black px-2 py-1 rounded shadow-xl ${
              buttonDisabled.rechargingSpeed ? 'cursor-not-allowed opacity-100' : ''
            }`}
            onClick={() => handleButtonClick('rechargingSpeed')}
            disabled={buttonDisabled.rechargingSpeed}
          >
            <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            2000
          </button>
        </div>
      </div>
    );
  } else if (activeTab === 'refTasks') {
    return (
      <div className="p-4 w-full">
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Invite 1 Friend</span>
          <button className={`flex text-sm text-black px-2 py-1 rounded shadow-xl 
          ${buttonDisabled.invite1 ? 'cursor-not-allowed opacity-100' : ''}`}
          onClick={()=> handleInvite('invite1')}
          disabled={buttonDisabled.invite1}
          >
            <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            50
          </button>
        </div>
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Invite 3 Friends</span>
          <button className={`flex text-sm text-black px-2 py-1 rounded shadow-xl 
          ${buttonDisabled.invite3 ? 'cursor-not-allowed opacity-100' : ''}`}
          onClick={()=> handleInvite('invite3')}
          disabled={buttonDisabled.invite3}
          ><img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            600
          </button>
        </div>
        <div
          className="task-card flex justify-between items-center bg-gray-800 rounded-lg p-4 mb-4 shadow-lg transform hover:scale-105 transition-transform duration-300"
          style={taskCardStyle}
        >
          <span>Invite 10 Friends</span>
          <button className={`flex text-sm text-black px-2 py-1 rounded shadow-xl 
          ${buttonDisabled.invite10 ? 'cursor-not-allowed opacity-100' : ''}`}
          onClick={()=> handleInvite('invite10')}
          disabled={buttonDisabled.invite10}
          >
            <img src={icon} alt="" className="w-6 h-6 mb-1 mx-1" />
            6000
          </button>
        </div>
      </div>
    );
  }
  return null;
};

export default TabContent;
