import React, { useState, useContext,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';


const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { setIsAuthenticated } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    const user = {
      username: username,
      password: password,
    };
    try {
      const response = await axios.post(`https://api.oxswap.app/admin/login`, user);
      if (response.data) {
        setIsAuthenticated(true);
        navigate('/admin');
      } else {
        setError('Invalid credentials. Please try again.');
        setTimeout(() => {
          setError('');
        }, 2000); // Clear error message after 2 seconds
      }
    } catch (error) {
      setError('Error logging in. Please try again later.');
      setTimeout(() => {
        setError('');
      }, 2000); // Clear error message after 2 seconds
    }
  };
  const handleBack = () => {
    navigate('/');
  }


 
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#111827] to-[#45526e] text-white">
      <div className="absolute top-0 left-0 p-6 space-y-4 md:space-y-6 sm:p-8">
        <button
          onClick={handleBack}
          className="text-white bg-[#cd8d30] px-3 py-1 rounded-md focus:outline-none"
        >
          Back
        </button>
      </div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 bg-gray-800 rounded-lg shadow-lg">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl">
          Sign in to your account
        </h1>
        <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="username" className="block mb-2 text-sm font-medium text-white">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder='Username'
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Password'
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full text-white bg-[#CD8D30] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Sign in
          </button>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
