import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext'
import { useNavigate } from 'react-router-dom';

const User = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext); // Assuming user and setIsAuthenticated are from context

  const handleLogout = () => {
    setIsAuthenticated(false); // Set isAuthenticated to false on logout
    // Perform any additional logout actions (clear tokens, etc.)
  };

  const handleBack = () => {
    navigate('/admin');
  }
  const { id } = useParams();
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`https://api.oxswap.app/detail/${id}`);
      console.log(response.data);
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className='w-12/12  min-h-screen p-5 bg-gradient-to-b from-[#111827] to-[#45526e] text-white'>
      <div className="flex justify-between mb-5">
        <button
          onClick={handleBack}
          className="text-white bg-[#cd8d30] px-3 py-1 rounded-md  focus:outline-none"
        >
          Back
        </button>
        <button
          onClick={handleLogout}
          className="text-white bg-red-700 px-3 py-1 rounded-md hover:bg-red-600 focus:outline-none"
        >
          Logout
        </button>

      </div>
      <div>
        <h1 className='text-white text-center font-extrabold text-2xl mb-10 pt-5'>User Detail</h1>
      </div>
      <div className='w-full'>
        <table className='text-white w-full'>
          <tbody className='w-full'>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>User Id</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.tele_id}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Earned Points</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.total_coins}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Referrals Count</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.total_ref}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Referral Code</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.code}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Connect Address</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.connect_address ? user.connect_address : "Not Provided"}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Telegram</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.telegram ? "Followed" : "Not Follow"}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>Twitter</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.twitter ? "Followed" : "Not Follow"}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>1 Week Bot</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.bot1 ? "Purchased" : "Not Purchase"}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>2 Week Bot</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.bot2 ? "Purchased" : "Not Purchase"}</td>
            </tr>
            <tr className='flex w-full'>
              <td className='w-1/2 py-2 border-y font-semibold border-slate-700'>1 Month Bot</td>
              <td className='w-1/2 py-2 border-y font-light border-slate-700'>{user.bot3 ? "Purchased" : "Not Purchase"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default User;
